<div class="flex flex-col md:pt-0 md:px-0 md:justify-center items-center border-gray-50 bg-white min-w-full no-zoom">
  <a href="https://wa.me/5215542004088" target="_blank"
    class="fixed bottom-4 right-4 flex items-center justify-center text-white  py-2 px-4 rounded-lg gap-3 whatsapp-container cursor-pointer ">
    <img class="h-5 w-5" [src]="whatsappImage" fill="none">
    <span>{{"loginWhatsappMessage" | translate}}</span>
  </a>

  <div class="fixed h-10 z-50 w-full flex justify-end items-center p-4 md:px-0 info-navbar md:pr-5 nav-transition"
    [ngClass]="scrolled ? '-top-10' : 'top-0'">
    <div class="flex items-center text-right px-2">
      <a id="callToPhone" href="tel:+525542004088" class="flex items-center whitespace-nowrap">
        <img [src]="smartPhoneImage" alt="Smartphone Icon" class="w-4 h-4 mr-2">
        <span class="sticky-navbar-text">(+52) 5542 004088</span>
      </a>
    </div>
    <div class="flex items-center text-right px-2">
      <a id="whatsToPhone" href="https://wa.me/5215542004088" class="flex items-center whitespace-nowrap"
        target="_blank">
        <img [src]="whatsappImage" alt="WhatsApp Icon" class="w-4 h-4 mr-2">
        <span class="sticky-navbar-text">(+52) 5542 004088</span>
      </a>
    </div>
    <div class="relative mr-1" #dropdownMenu appClickOutside (clickOutside)="dropdownOpen = false">
      <button id="toggleDropdownLogin" (click)="toggleDropdown()"
        class="sticky-navbar-text p-1 rounded flex items-center">
        <img [src]="languageImage" alt="Language Icon" class="w-4 h-4 ml-2 mr-2">
        {{ currentLangShort }}
      </button>
      <div *ngIf="dropdownOpen" class="absolute right-0 mt-2 w-40 shadow-lg rounded selector-container">
        <div id="changeLanguageEng" (click)="changeLanguage('eng')"
          class="sticky-navbar-text p-2 dropdown-container cursor-pointer">English
        </div>
        <div id="changeLanguageEsp" (click)="changeLanguage('esp')"
          class="sticky-navbar-text p-2 dropdown-container cursor-pointer">Español
        </div>
      </div>
    </div>
  </div>

  <div id="navbar" class="fixed z-40 h-16 w-full flex flex-col md:flex-row items-center bg-white nav-transition"
    [ngClass]="scrolled ? 'top-0' : 'top-10'">
    <div class="flex items-center justify-between w-full md:hidden">
      <div class="md:hidden px-4 py-2 ">
        <button id="toggleMenuSide" (click)="toggleMenu()">
          <img [src]="menuIcon" alt="Menú" width="32px" height="32px" />
        </button>
      </div>

      <div class="flex items-center justify-start w-full md:w-1/5 px-4 md:pl-5">
        <img class="haibu-logo my-2" [src]="haibuImage" alt="">
      </div>

      <div class="mobile-menu nav-transition" [class.active]="isMenuOpen" [ngClass]="scrolled ? 'top-0' : 'top-10'">

        <div class="menu-header">
          <div class="menu-close-button">
            <button id="toggleMenuClose" (click)="toggleMenu()">
              <img [src]="closeIcon" alt="Cerrar" width="32px" height="32px">
            </button>
          </div>
          <img class="menu-haibu-logo pl-4" [src]="haibuImage" alt="Haibu Logo" />
        </div>
        <div class="flex flex-col justify-center p-4">

          <div class="menu-title pb-3">
            Menú
          </div>
          <div
            class="flex flex-col md:flex-row justify-center md:justify-end items-center space-y-2 md:space-y-0 md:space-x-4">
            <div id="scrollToElementRompe"
              class="hover:bg-gray-100 rounded-md lg:px-3 edit-text cursor-pointer text-center py-2 md:py-0 md:px-2 whitespace-nowrap"
              (click)="scrollToElement('rompe')">
              {{"loginPuzzle" | translate}}
            </div>
            <div id="scrollToElementModules"
              class="hover:bg-gray-100 rounded-md lg:px-3 edit-text cursor-pointer text-center py-2 md:py-0 md:px-2 whitespace-nowrap"
              (click)="scrollToElement('modules')">
              {{"loginModules" | translate}}
            </div>
            <div id="scrollToElementPrice"
              class="hover:bg-gray-100 rounded-md lg:px-3 edit-text cursor-pointer text-center py-2 md:py-0 md:px-2 whitespace-nowrap"
              (click)="scrollToElement('price')">
              {{"loginPrices" | translate}}
            </div>
            <div id="scrollToElementDemo"
              class="hover:bg-gray-100 rounded-md lg:px-3 edit-text cursor-pointer text-center py-2 pb-6 md:py-0 md:px-2 whitespace-nowrap"
              (click)="scrollToElement('demo')">
              {{"loginAskDemo" | translate}}
            </div>
          </div>
          <app-custom-primary-button id="openLoginModal" (click)="openLoginModal()" color="secondary" size="small"
            class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden">
            {{"loginEnter" | translate}}
          </app-custom-primary-button>
        </div>

        <div class="footer-section flex flex-col justify-center items-center mt-auto">
          <div class="footer-text mb-4">© 2023 Haibu. All rights reserved</div>
          <div class="additional-text mb-4">{{'loginPrivacyAdvice' | translate}}</div>
          <div class="social-icons flex space-x-4">
            <a id="linkedinMobileSideMenu" href="https://www.linkedin.com/company/haibuhr/about/" target="_blank">
              <img [src]="linkedinIcon" alt="Linkedin">
            </a>
            <a id="facebookMobileSideMenu" href="https://www.facebook.com/haibu.hr" target="_blank">
              <img [src]="fbIcon" alt="Facebook">
            </a>
            <a id="instagramMobileSideMenu" href="https://www.instagram.com/haibu.hr/" target="_blank">
              <img [src]="igIcon" alt="Instagram">
            </a>
            <a id="youtubeMobileSideMenu" href="" target="_blank">
              <img [src]="ytIcon" alt="Youtube">
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden md:flex flex-grow justify-between items-center rounded-md p-0 lg:px-4 lg:pr-5 ">
      <div class="flex items-center justify-start w-full md:w-1/5 p-0 md:pl-1 lg:px-4 lg:pl-5">
        <img class="haibu-logo my-2" [src]="haibuImage" alt="">
      </div>
      <div class="flex-grow flex justify-center md:justify-end items-center">
        <div id="scrollToElementRompe2"
          class="hover:bg-gray-100 rounded-md lg:px-3 edit-text cursor-pointer text-center py-2 md:py-0 md:px-2 lg:mr-4 whitespace-nowrap"
          (click)="scrollToElement('rompe')">
          {{"loginPuzzle" | translate}}
        </div>
        <div id="scrollToElementModules2"
          class="hover:bg-gray-100 rounded-md lg:px-3 edit-text cursor-pointer text-center py-2 md:py-0 md:px-2 lg:mr-4 whitespace-nowrap"
          (click)="scrollToElement('modules')">
          {{"loginModules" | translate}}
        </div>
        <div id="scrollToElementPrice2"
          class="hover:bg-gray-100 rounded-md lg:px-3 edit-text cursor-pointer text-center py-2 md:py-0 md:px-2 lg:mr-4 whitespace-nowrap"
          (click)="scrollToElement('price')">
          {{"loginPrices" | translate}}
        </div>
        <div id="scrollToElementDemo2"
          class="hover:bg-gray-100 rounded-md lg:px-3 edit-text cursor-pointer text-center py-2 md:py-0 md:px-2 lg:mr-4 whitespace-nowrap"
          (click)="scrollToElement('demo')">
          {{"loginAskDemo" | translate}}
        </div>
      </div>
      <div class="w-full md:w-auto md:pr-2">
        <app-custom-primary-button id="openLoginModal2" (click)="openLoginModal()" color="secondary" size="small"
          class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden">
          {{"loginEnter" | translate}}
        </app-custom-primary-button>
      </div>
    </div>
  </div>

  <div class="bg-white w-full flex flex-col justify-center items-center nav-transition"
    [ngClass]="scrolled ? '' : 'sliders'">

    <div class="slider-container slider-image relative hidden xl:flex w-full">
      <ng-image-slider #navDesktop class="w-full" [images]="imageObject" [infinite]="true" [autoSlide]="false"
        [showArrow]="false" [imagePopup]="false" [imageSize]="{width: '100%', height: '70%'}"
        slideImage="1"></ng-image-slider>
      <!-- <div class="controls-container absolute top-1/2 left-0 ml-4 transform -translate-y-1/4">
        <button id="navigatePrevDesktop" (click)="navigate('prev', 'desktop')" class="btn-prev mr-2 w-auto">
          <img [src]="leftArrowImage" alt="Anterior">
        </button>
        <button id="navigateNextDesktop" (click)="navigate('next', 'desktop')" class="btn-next w-auto ml-4">
          <img [src]="rightArrowImage" alt="Siguiente">
        </button>
      </div> -->
      <div class="custom-text-display ml-6 mt-24 z-50">
        <div class="title text-lg md:text-xl font-bold break-words max-w-lg">
          {{ 'loginSliders.title' + currentImageId | translate }}
        </div>
        <div class="subtitle mt-6 mb-6 text-base md:text-lg break-words max-w-lg">
          {{ 'loginSliders.subtitle' + currentImageId | translate }}
        </div>
        <app-custom-primary-button id="scrollToElementDemo3" color="primary" size="demo-size"
          (click)="scrollToElement('demo')" class="w-full md:w-auto ">
          {{ 'loginAskDemo' | translate }}
        </app-custom-primary-button>
      </div>
    </div>

    <div class="slider-container lg:flex xl:hidden w-full hidden">
      <ng-image-slider #navLaptop class="w-full" [images]="imageObjectL" [infinite]="true" [autoSlide]="false"
        [showArrow]="false" [imagePopup]="false" [imageSize]="{width: '100%', height: '70%'}"
        slideImage="1"></ng-image-slider>
      <!-- <div class="controls-container absolute top-1/2 left-0 ml-4 transform -translate-y-1/4">
        <button id="navigatePrevLaptop" (click)="navigate('prev', 'laptop')" class="btn-prev mr-2 w-auto">
          <img [src]="leftArrowImage" alt="Anterior">
        </button>
        <button id="navigateNextLaptop" (click)="navigate('next', 'laptop')" class="btn-next w-auto ml-4">
          <img [src]="rightArrowImage" alt="Siguiente">
        </button>
      </div> -->
      <div class="custom-text-display ml-6 mt-24">
        <div class="title text-lg md:text-xl font-bold break-words max-w-lg">
          {{ 'loginSliders.title' + currentImageId | translate }}
        </div>
        <div class="subtitle mt-6 mb-6 text-base md:text-lg break-words max-w-lg">
          {{ 'loginSliders.subtitle' + currentImageId | translate }}
        </div>
        <app-custom-primary-button id="scrollToElementDemo4" color="primary" size="demo-size"
          (click)="scrollToElement('demo')" class="w-full md:w-auto">
          {{ 'loginAskDemo' | translate }}
        </app-custom-primary-button>
      </div>
    </div>

    <div class="slider-container md:flex lg:hidden w-full hidden">
      <ng-image-slider #navTablet class="w-full" [images]="imageObjectT" [infinite]="true" [autoSlide]="false"
        [showArrow]="false" [imagePopup]="false" [imageSize]="{width: '100%', height: '60%'}"
        slideImage="1"></ng-image-slider>
      <!-- <div class="controls-container absolute top-1/2 left-0 ml-4 transform -translate-y-1/4">
        <button id="navigatePrevTablet" (click)="navigate('prev', 'tablet')" class="btn-prev mr-2 w-auto">
          <img [src]="leftArrowImage" alt="Anterior">
        </button>
        <button id="navigateNextTablet" (click)="navigate('next', 'tablet')" class="btn-next w-auto ml-4">
          <img [src]="rightArrowImage" alt="Siguiente">
        </button>
      </div> -->
      <div class="custom-text-display ml-6 mt-24">
        <div class="title text-lg md:text-xl font-bold break-words max-w-lg">
          {{ 'loginSliders.title' + currentImageId | translate }}
        </div>
        <div class="subtitle mt-6 mb-6 text-base md:text-lg break-words max-w-lg">
          {{ 'loginSliders.subtitle' + currentImageId | translate }}
        </div>
        <app-custom-primary-button id="scrollToElementDemo5" color="primary" size="demo-size"
          (click)="scrollToElement('demo')" class="w-full md:w-auto">
          {{ 'loginAskDemo' | translate }}
        </app-custom-primary-button>
      </div>
    </div>

    <div class="slider-container md:py-4 flex md:hidden w-full" [class.hidden-slider]="isMenuOpen">
      <ng-image-slider #navMobile class="w-full" [images]="imageObjectM" [infinite]="true" [autoSlide]="false"
        [imagePopup]="false" [imageSize]="{width: '100%', height: '550px'}" slideImage="1"
        [showArrow]="false"></ng-image-slider>
      <!-- <div class="controls-container absolute top-1/2 left-0 ml-4 transform -translate-y-1/4">
        <button id="navigatePrevMobile" (click)="navigate('prev', 'mobile')" class="btn-prev mr-2 w-auto">
          <img [src]="leftArrowImage" alt="Anterior" class="btn-next-mobile">
        </button>
        <button id="navigateNextMobile" (click)="navigate('next', 'mobile')" class="btn-next w-auto ml-4">
          <img [src]="rightArrowImage" alt="Siguiente" class="btn-next-mobile">
        </button>
      </div> -->
      <div class="custom-text-display ml-6 mt-32">
        <div class="title font-dekar break-words max-w-xs sm:max-w-sm md:max-w-md">{{ 'loginSliders.title' +
          currentImageId | translate }}</div>
        <div class="subtitle mt-3 mb-2 break-words max-w-xs sm:max-w-sm md:max-w-md">{{ 'loginSliders.subtitle' +
          currentImageId | translate }}</div>
        <app-custom-primary-button id="scrollToElementDemo6" color="primary" size="demo-size"
          (click)="scrollToElement('demo')" class="w-full md:w-auto">
          {{ 'loginAskDemo' | translate }}
        </app-custom-primary-button>
      </div>
    </div>

    <div class="w-full">
      <div class="responsive-iframe-container">
        <iframe src="https://www.youtube.com/embed/uf3ktPfqfMA" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>

    <div class="bg-secondary w-full">
      <div class="px-4 md:px-20">
        <div class="flex flex-col lg:flex-row w-full items-stretch justify-center text-center px-4 lg:px-6 py-10">
          <div class="flex flex-col items-center justify-center mb-8 lg:mb-0 lg:mx-2 xl:mx-4 w-full lg:w-1/3 h-full">
            <div class="flex items-center justify-center w-20 h-20 rounded-full p-2 overflow-hidden bg-white">
              <img class="w-20 h-auto mx-auto" [src]="candidateIcon" alt="">
            </div>
            <div class="text-center pt-2 stats-number">
              {{ stats?.totalCandidates }}
            </div>
            <div class="text-center unique-text pt-1 text-sm">
              {{"loginCandidates" | translate}}
            </div>
          </div>

          <div class="flex flex-col items-center justify-center mb-8 lg:mb-0 lg:mx-2 xl:mx-4 w-full lg:w-1/3 h-full">
            <div class="flex items-center justify-center w-20 h-20 rounded-full p-2 overflow-hidden bg-white">
              <img class="w-20 h-auto mx-auto" [src]="userIcon" alt="">
            </div>
            <div class="text-center pt-2 stats-number">
              {{ stats?.totalUsers }}
            </div>
            <div class="text-center unique-text pt-1 text-sm">
              {{"loginUsers" | translate}}
            </div>
          </div>

          <div class="flex flex-col items-center justify-center mb-8 lg:mb-0 lg:mx-2 xl:mx-4 w-full lg:w-1/3 h-full">
            <div class="flex items-center justify-center w-20 h-20 rounded-full p-2 overflow-hidden bg-white">
              <img class="w-20 h-auto mx-auto" [src]="clientIcon" alt="">
            </div>
            <div class="text-center pt-2 stats-number">
              {{ stats?.totalCompanies }}
            </div>
            <div class="text-center unique-text pt-1 text-sm">
              <div>{{"loginCompanies" | translate}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-4 md:px-20 pb-12 background-gif">
      <div class="flex flex-col md:flex-row w-full">
        <div class="w-full md:w-1/2 px-6 py-4 md:py-0 flex items-center justify-center">
          <img class="w-3/4 md:w-2/3 lg:w-3/4 h-auto image-rendering" [src]="gifIcon" alt="">
        </div>
        <div id="rompe" class="w-full md:w-1/2 px-6">
          <div class="w-full pt-12 pb-6 subtitle-gray font-medium text-left">
            {{"loginFindTalent" | translate}}
          </div>
          <div class="pb-6 pt-2 md:text-3xl text-left green-title green-title-size">
            {{"loginWhyRecruiter" | translate}}
          </div>
          <div class="pt-6 pr-3 text-justify meaning-haibu-text" [innerHTML]="summaryText"></div>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap px-4 md:pr-20 lg:px-28 bg-white">
      <div id="modules" class="w-full pt-8 pb-4 px-8 green-title green-title-size text-center">
        {{"loginModules" | translate}}
      </div>
      <!-- Primera columna -->
      <div class="w-full md:w-1/3 px-8 py-8 flex flex-col justify-between items-stretch">
        <!-- Sección 1 -->
        <div class="mb-8">
          <div>
            <img class="w-28 h-auto mx-auto" src="../../assets/animations/red-en-la-nube.gif" alt="Nube">
          </div>
          <h2 class="text-left blue-container-text mb-2 whitespace-nowrap">{{"loginDigitalized" | translate}}</h2>
          <p class="text-left text-help-haibu">{{"loginSelectCandidate" | translate}}</p>
        </div>
        <!-- Sección 2 -->
        <div>
          <div>
            <img class="w-28 h-auto mx-auto" src="../../assets/animations/cliente.gif" alt="Nube">
          </div>
          <h2 class="text-left blue-container-text mb-2 whitespace-nowrap">{{"loginTeamTitle" | translate}}</h2>
          <p class="text-left text-help-haibu break-words">{{"loginFirstTalent" | translate}}</p>
        </div>
      </div>

      <!-- Segunda columna (Imagen) -->
      <div class="w-full md:w-1/3 flex justify-center items-center px-8 py-8 md:py-12">
        <div class="outer-circle">
          <div class="outer-circle-wrapper">
            <div class="circle-wrapper">
              <img [src]="actualImage" alt="Logo Haibu" class="image-style w-28 p-1">
            </div>
          </div>
        </div>
      </div>

      <!-- Tercera columna -->
      <div class="w-full md:w-1/3 px-8 py-8 flex flex-col justify-between items-stretch">
        <!-- Sección 1 -->
        <div class="mb-8">
          <div>
            <img class="w-28 h-auto mx-auto" src="../../assets/animations/presentacion.gif" alt="Nube">
          </div>
          <h2 class="text-left blue-container-text mb-2 whitespace-nowrap">{{"loginFilterAndSelect" | translate}}</h2>
          <p class="text-left text-help-haibu ">{{"loginFiltersTitle" | translate}}</p>
        </div>
        <!-- Sección 2 -->
        <div>
          <div>
            <img class="w-28 h-auto mx-auto" src="../../assets/animations/hexagonal.gif" alt="Nube">
          </div>
          <h2 class="text-left blue-container-text mb-2">{{"loginEasyFeatures" | translate}}</h2>
          <p class="text-left text-help-haibu">{{"loginEasyFeaturesTalent" | translate}}</p>
        </div>
      </div>
    </div>

    <div class="bg-secondary w-full">
      <div class="px-4 md:px-20">
        <div class="w-full pt-12 subtitle-gray font-medium text-center">
          {{"loginFindTalent" | translate}}
        </div>
        <div class="w-full py-8 md:py-12 px-8 green-title green-title-size text-center">
          {{"loginContinue" | translate}}
        </div>
        <div class="flex flex-col lg:flex-row w-full items-stretch justify-center text-center px-4 lg:px-6 pb-12 pt-4">
          <div class="flex flex-col items-center justify-center mb-8 lg:mb-0 lg:mx-2 xl:mx-4 w-full lg:w-1/3 h-full">
            <div class="flex items-center justify-center h-full lg:h-20">
              <img class="w-16 lg:w-20 h-auto mx-auto" [src]="teamSettingsIcon" alt="">
            </div>
            <div class="text-center unique-text pt-4 text-base lg:text-sm">
              {{"loginOneUser" | translate}}
            </div>
          </div>

          <div class="flex flex-col items-center justify-center mb-8 lg:mb-0 lg:mx-2 xl:mx-4 w-full lg:w-1/3 h-full">
            <div class="flex items-center justify-center h-full lg:h-20">
              <img class="w-16 lg:w-20 h-auto mx-auto" [src]="teamSquareIcon" alt="">
            </div>
            <div class="text-center unique-text pt-4 text-base lg:text-sm">
              {{"loginOneUserPrice" | translate}}
            </div>
          </div>

          <div class="flex flex-col items-center justify-center mb-8 lg:mb-0 lg:mx-2 xl:mx-4 w-full lg:w-1/3 h-full">
            <div class="flex items-center justify-center h-full lg:h-20">
              <img class="w-16 lg:w-20 h-auto mx-auto" [src]="teamPuzzleIcon" alt="">
            </div>
            <div class="text-center unique-text pt-4 text-base lg:text-sm">
              <div>{{"loginOneUser2" | translate}}</div>
              <div>{{"loginOneUserPrice2" | translate}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full py-8">
      <div class="px-4 md:px-20 lg:px-40">
        <div class="flex flex-col md:flex-row">
          <div>
            <h2 class="text-left blue-container-text mb-2 whitespace-nowrap">{{"navbarDashboard" | translate}}</h2>
            <p class="text-left text-help-haibu break-words">{{"dashboardLoginD" | translate}}</p>
          </div>
          <div>
            <img class="px-0 md:px-12" src="../../assets/img/Dashboard.png" alt="Dashboard Icon">
          </div>
        </div>
      </div>
    </div>

    <div class="px-4 py-10 lg:px-28 xl:px-36 w-full plan-table overflow-hidden flex justify-start lg:justify-center">
      <mat-table #table [dataSource]="dataSource" class="mat-elevation-z0 w-full py-3 plan-table-mat">

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef class="font-bold"></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="font-bold text-sm">{{ element.name | translate }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="entry">
          <mat-header-cell *matHeaderCellDef>
            <div class="font-bold text-center w-full">
              {{ 'plan.1' | translate }}
              <span [innerHTMl]="'plan.1' | translate"></span>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="text-center w-full font-lg">
              <mat-icon *ngIf="element.entry" class="text-green-500 font-bold">check</mat-icon>
              <mat-icon *ngIf="!element.entry" class="text-red-500 font-bold">close</mat-icon>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="entryHH">
          <mat-header-cell *matHeaderCellDef>
            <div class="font-bold text-center w-full px-0 md:px-5">
              {{ 'plan.2' | translate }}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="text-center w-full font-lg">
              <mat-icon *ngIf="element.entryHH" class="text-green-500 font-bold">check</mat-icon>
              <mat-icon *ngIf="!element.entryHH" class="text-red-500 font-bold">close</mat-icon>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="mid">
          <mat-header-cell *matHeaderCellDef>
            <div class="font-bold text-center w-full">
              {{ 'plan.3' | translate }}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="text-center w-full font-lg">
              <mat-icon *ngIf="element.mid" class="text-green-500 font-bold">check</mat-icon>
              <mat-icon *ngIf="!element.mid" class="text-red-500 font-bold">close</mat-icon>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="midHH">
          <mat-header-cell *matHeaderCellDef>
            <div class="font-bold text-center w-full px-0 md:px-5">
              {{ 'plan.4' | translate }}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="text-center w-full">
              <mat-icon *ngIf="element.midHH" class="text-green-500 font-bold">check</mat-icon>
              <mat-icon *ngIf="!element.midHH" class="text-red-500 font-bold">close</mat-icon>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="enterprise">
          <mat-header-cell *matHeaderCellDef>
            <div class="font-bold text-center w-full">
              {{ 'plan.5' | translate }}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="text-center w-full">
              <mat-icon *ngIf="element.enterprise" class="text-green-500 font-bold">check</mat-icon>
              <mat-icon *ngIf="!element.enterprise" class="text-red-500 font-bold">close</mat-icon>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>

    <div class="w-full background-mixed">
      <div id="price" class="px-5 md:px-8">
        <div class="pt-9 pb-6">
          <div class="green-title green-title-size text-center">
            {{"loginOneUser3" | translate}}
          </div>
        </div>
        <div>
          <div class="meaning-haibu-text meaning-size-alter text-left">
            {{"loginOneUserPrice3" | translate}}
          </div>
        </div>
        <div class="mt-6 flex justify-center">
          <div class="subscription-switch-cont">
            <div class="flex items-center justify-center w-auto text-center">
              <button
                class="py-2 px-4 w-28 rounded-l-full bg-white text-gray-900 font-medium focus:outline-none text-sm"
                [ngClass]="{'background-haibu text-white': subscriptionType === 'mensual'}"
                (click)="setSubscriptionType('mensual')">
                {{ 'mensual' | translate }}
              </button>
              <button
                class="py-2 px-4 w-28 rounded-r-full bg-white text-gray-900 font-medium focus:outline-none text-sm"
                [ngClass]="{'background-haibu text-white': subscriptionType === 'semestral'}"
                (click)="setSubscriptionType('semestral')">
                {{ 'semestral' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div class="flex flex-col md:flex-row justify-center gap-4 py-6 pt-2 relative price-container">
          <div class="absolute left-0 top-1/2 flex items-center justify-center translate-item z-30">
            <div class="arrow-price rounded-full w-10 h-10 flex items-center justify-center cursor-pointer" (click)="scrollLeft()">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </div>
          </div>
          <div #priceCards class="horizontal-scroll py-4 px-4">
            <div class="flex flex-nowrap gap-4">
              <!-- Card 1 -->
              <div class="card rounded-lg overflow-hidden shadow-lg bg-white p-6 flex flex-col items-center">
                <div class="tag text-center mb-5 px-3 py-2">{{ 'plan.1' | translate }}</div>
                <div class="border-t border-white-300 w-full max-w-lg mx-auto pt-8 pb-4">
                  <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center mb-3">
                    <div class="price-text2 text-secondary"> {{ 'price.1.canceled' | translate }}</div>
                    <div class="bycompany-text2 ml-1 text-secondary">MXN*</div>
                  </div>
                  <div class="w-full max-w-lg mx-auto pb-2">
                    <p *ngIf="subscriptionType === 'semestral'"
                      class="text-center mb-6 pb-2 bycompany-text text-primary">
                      {{ 'byCompany.' + subscriptionType | translate }}
                    </p>
                  </div>
                  <div class="flex justify-center mb-2 pb-2">
                    <div class="price-text text-primary"> {{ 'price.1.' + subscriptionType | translate }}</div>
                    <div class="bycompany-text text-primary ml-2">MXN*</div>
                  </div>
                </div>
                <div class="border-b border-primary-300 w-full max-w-lg mx-auto pb-2">
                  <p class="text-center mb-4 pb-2 bycompany-text text-primary">
                    {{ 'mensual' | translate }}
                  </p>
                </div>
                <!-- Botón o acción -->
                <div class="mt-4">
                  <a id="subscriptionUrl" [href]="getSuscriptionUrl(1)" target="_blank">
                    <app-custom-primary-button color="primary" size="small"
                      class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden">
                      {{ 'callToAction.' + subscriptionType | translate }}
                    </app-custom-primary-button>
                  </a>
                </div>
                <p class="text-center align-bottom pt-5 bycompany-text credit-card-text text-black">
                  {{ 'loginNoCreditCard'| translate }}
                </p>
                <p class="text-center align-bottom bycompany-text credit-card-text text-black">
                  *{{ 'loginNoTax'| translate }}
                </p>
              </div>
              <!-- Card 2 -->
              <div class="card rounded-lg overflow-hidden shadow-lg background-haibu p-6 flex flex-col items-center">
                <div class="tag text-center mb-5 px-3 py-2">{{ 'plan.2' | translate }}</div>
                <div class="border-t border-white-300 w-full max-w-lg mx-auto pt-8 pb-4">
                  <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center mb-3">
                    <div class="price-text2"> {{ 'price.2.canceled' | translate }}</div>
                    <div class="bycompany-text2 ml-1">MXN*</div>
                  </div>
                  <div class="w-full max-w-lg mx-auto pb-2">
                    <p *ngIf="subscriptionType === 'semestral'" class="text-center mb-6 pb-2 bycompany-text ">
                      {{ 'byCompany.' + subscriptionType | translate }}
                    </p>
                  </div>
                  <div class="flex justify-center mb-2 pb-2">
                    <div class="price-text"> {{ 'price.2.' + subscriptionType | translate }}</div>
                    <div class="bycompany-text ml-2">MXN*</div>
                  </div>
                </div>
                <div class="border-b border-white-300 w-full max-w-lg mx-auto pb-2">
                  <p class="text-center mb-4 pb-2 bycompany-text ">
                    {{ 'mensual' | translate }}
                  </p>
                </div>
                <!-- Botón o acción -->
                <div class="mt-4">
                  <a id="subscriptionUrl" [href]="getSuscriptionUrl(2)" target="_blank">
                    <app-custom-primary-button color="secondary" size="small"
                      class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden">
                      {{ 'callToAction.' + subscriptionType | translate }}
                    </app-custom-primary-button>
                  </a>
                </div>
                <p class="text-center align-bottom pt-5 bycompany-text credit-card-text">
                  {{ 'loginNoCreditCard'| translate }}
                </p>
                <p class="text-center align-bottom bycompany-text credit-card-text">
                  *{{ 'loginNoTax'| translate }}
                </p>
              </div>
              <!-- Card 3 -->
              <div class="card rounded-lg overflow-hidden shadow-lg bg-white p-6 flex flex-col items-center">
                <div class="tag text-center mb-5 px-3 py-2">{{ 'plan.3' | translate }}</div>
                <div class="border-t border-white-300 w-full max-w-lg mx-auto pt-8 pb-4">
                  <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center mb-3">
                    <div class="price-text2 text-secondary"> {{ 'price.3.canceled' | translate }}</div>
                    <div class="bycompany-text2 ml-1 text-secondary">MXN*</div>
                  </div>
                  <div class="w-full max-w-lg mx-auto pb-2">
                    <p *ngIf="subscriptionType === 'semestral'"
                      class="text-center mb-6 pb-2 bycompany-text text-primary">
                      {{ 'byCompany.' + subscriptionType | translate }}
                    </p>
                  </div>
                  <div class="flex justify-center mb-2 pb-2">
                    <div class="price-text text-primary"> {{ 'price.3.' + subscriptionType | translate }}</div>
                    <div class="bycompany-text text-primary ml-2">MXN*</div>
                  </div>
                </div>
                <div class="border-b border-primary-300 w-full max-w-lg mx-auto pb-2">
                  <p class="text-center mb-4 pb-2 bycompany-text text-primary">
                    {{ 'mensual' | translate }}
                  </p>
                </div>
                <!-- Botón o acción -->
                <div class="mt-4">
                  <a id="subscriptionUrl" [href]="getSuscriptionUrl(3)" target="_blank">
                    <app-custom-primary-button color="primary" size="small"
                      class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden">
                      {{ 'callToAction.' + subscriptionType | translate }}
                    </app-custom-primary-button>
                  </a>
                </div>
                <p class="text-center align-bottom pt-5 bycompany-text credit-card-text text-black">
                  {{ 'loginNoCreditCard'| translate }}
                </p>
                <p class="text-center align-bottom bycompany-text credit-card-text text-black">
                  *{{ 'loginNoTax'| translate }}
                </p>
              </div>
              <!-- Card 4 -->
              <div class="card rounded-lg overflow-hidden shadow-lg background-haibu p-6 flex flex-col items-center">
                <div class="tag text-center mb-5 px-3 py-2">{{ 'plan.4' | translate }}</div>
                <div class="border-t border-white-300 w-full max-w-lg mx-auto pt-8 pb-4">
                  <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center mb-3">
                    <div class="price-text2"> {{ 'price.4.canceled' | translate }}</div>
                    <div class="bycompany-text2 ml-1">MXN*</div>
                  </div>
                  <div class="w-full max-w-lg mx-auto pb-2">
                    <p *ngIf="subscriptionType === 'semestral'" class="text-center mb-6 pb-2 bycompany-text ">
                      {{ 'byCompany.' + subscriptionType | translate }}
                    </p>
                  </div>
                  <div class="flex justify-center mb-2 pb-2">
                    <div class="price-text"> {{ 'price.4.' + subscriptionType | translate }}</div>
                    <div class="bycompany-text ml-2">MXN*</div>
                  </div>
                </div>
                <div class="border-b border-white-300 w-full max-w-lg mx-auto pb-2">
                  <p class="text-center mb-4 pb-2 bycompany-text ">
                    {{ 'mensual' | translate }}
                  </p>
                </div>
                <!-- Botón o acción -->
                <div class="mt-4">
                  <a id="subscriptionUrl" [href]="getSuscriptionUrl(4)" target="_blank">
                    <app-custom-primary-button color="secondary" size="small"
                      class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden">
                      {{ 'callToAction.' + subscriptionType | translate }}
                    </app-custom-primary-button>
                  </a>
                </div>
                <p class="text-center align-bottom pt-5 bycompany-text credit-card-text">
                  {{ 'loginNoCreditCard'| translate }}
                </p>
                <p class="text-center align-bottom bycompany-text credit-card-text">
                  *{{ 'loginNoTax'| translate }}
                </p>
              </div>
              <!-- Card 5 -->
              <div
                class="card rounded-lg overflow-hidden shadow-lg bg-white p-6 flex flex-col items-stretch justify-between">
                <div>
                  <div class="tag text-center mb-5 px-3 py-2">{{ 'plan.5' | translate }}</div>
                  <div class="border-t border-white-300 w-full max-w-lg mx-auto pt-8 pb-4">
                    <p class="text-center mb-2 pb-2 bycompany-text text-primary mt-8">
                      {{ 'personalizedPlan' | translate }}
                    </p>
                    <div class="flex justify-center mb-2 pb-2">
                      <div class="contact-price-text text-primary text-center">
                        {{ 'price.5.' + subscriptionType | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="border-b border-primary-300 w-full max-w-lg mx-auto pb-2">
                  </div>
                  <!-- Botón o acción -->
                  <div class="mt-4">
                    <a id="subscriptionUrl" [href]="getSuscriptionUrl(5)" target="_blank">
                      <app-custom-primary-button color="primary" size="small"
                        class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden">
                        {{ 'price.5.' + subscriptionType | translate }}
                      </app-custom-primary-button>
                    </a>
                  </div>
                  <p class="text-center align-bottom pt-5 bycompany-text credit-card-text text-black">
                    {{ 'loginNoCreditCard'| translate }}
                  </p>
                  <p class="text-center align-bottom bycompany-text credit-card-text text-black">
                    *{{ 'loginNoTax'| translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="absolute right-0 top-1/2 flex items-center justify-center translate-item z-30">
            <div class="arrow-price rounded-full w-10 h-10 flex items-center justify-center cursor-pointer" (click)="scrollRight()">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="px-4 md:px-20 w-full background-green py-12 items-center justify-center">
    <div class="flex flex-col w-full items-center justify-center">
      <div class="w-full md:w-3/4  flex items-center justify-center">
        <div class="rounded-md w-full md:w-full">
          <div id="demo" class="bg-white rounded-lg p-6 shadow-md">
            <div>
              <div class="subtitle-gray text-center pt-4">
                {{"loginAskForADemo" | translate}}
              </div>
              <div class="benefits-text pt-4 pb-4">
                {{"loginWantAllBenefits" | translate}}
              </div>
              <div class="text-form">
                {{"loginFillAllForm" | translate}}
              </div>
              <div class="text-form pb-2">
                {{"scheduleCallNow" | translate}} <a class="goodtime"
                  href="https://meet.goodtime.io/w/haibuhrcom/jgasteasoro/video-call"
                  target="_blank">{{"scheduleCallNow2" | translate}}</a>.
              </div>
            </div>
            <form [formGroup]="demoForm" class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full border-radius">
                  <mat-label for="loginNameInput">{{ loginNamePlaceHolder }}</mat-label>
                  <input #nameInput id="loginNameInput" maxlength="40" formControlName="names" matInput type="text"
                    class="validate w-full border-radius rounded-lg" [placeholder]="loginNamePlaceHolder" required
                    oninput="this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.]/g, '').trimStart();">
                </mat-form-field>
                <div
                  *ngIf="demoForm.get('names').invalid && (demoForm.get('names').dirty || demoForm.get('names').touched)"
                  class="text-red-500 text-xs mt-3 -mb-6">
                  <span *ngIf="demoForm.get('names').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: loginNamePlaceHolder } }}
                  </span>
                  <span *ngIf="demoForm.get('names').errors['noSpecialCharacters']">
                    {{ 'invalidCharactersKeyForm' | translate: { key: loginNamePlaceHolder } }}
                  </span>
                </div>
              </div>

              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label for="loginNameInput">{{ loginLastName }}</mat-label>
                  <input id="loginLastNameInput" maxlength="40" formControlName="lastnames" matInput type="text"
                    class="validate w-full" [placeholder]="loginLastName" required
                    oninput="this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.]/g, '').trimStart();">
                </mat-form-field>
                <div
                  *ngIf="demoForm.get('lastnames').invalid && (demoForm.get('lastnames').dirty || demoForm.get('lastnames').touched)"
                  class="text-red-500 text-xs mt-3 -mb-6">
                  <span *ngIf="demoForm.get('lastnames').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: loginLastName } }}
                  </span>
                  <span *ngIf="demoForm.get('lastnames').errors['noSpecialCharacters']">
                    {{ 'invalidCharactersKeyForm' | translate: { key: loginLastName } }}
                  </span>
                </div>
              </div>

              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full logindepaddme">
                  <mat-label for="loginNameInput">{{ loginEmail }}</mat-label>
                  <input id="loginEmailInput" maxlength="80" formControlName="email" matInput type="text"
                    class="validate" [placeholder]="loginEmail" required oninput="this.value = this.value.trim();">
                </mat-form-field>
                <div
                  *ngIf="demoForm.get('email').invalid && (demoForm.get('email').dirty || demoForm.get('email').touched)"
                  class="text-red-500 text-xs mt-3 -mb-6">
                  <span *ngIf="demoForm.get('email').errors['required']">{{ 'hasWhiteSpaceKeyForm' | translate: { key:
                    loginEmail } }}</span>
                  <span
                    *ngIf="!demoForm.get('email').errors['required'] && (demoForm.get('email').errors['email'] || demoForm.get('email').errors['isEmailValid'])">{{
                    'emailMassiveLinkValid' | translate }}</span>
                </div>
              </div>

              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full logindepaddme">
                  <mat-label for="loginNameInput">{{ loginCellPhone }}</mat-label>
                  <input id="loginCellPhoneInput" maxlength="14" minlength="10"
                    (keypress)="($event.charCode >= 48 && $event.charCode < 58)" formControlName="phone" matInput
                    type="text" class="validate" [placeholder]="loginCellPhone" required
                    oninput="this.value = this.value.replace(/[^0-9]/g, '').trim();">
                </mat-form-field>
                <div
                  *ngIf="demoForm.get('phone').invalid && (demoForm.get('phone').dirty || demoForm.get('phone').touched)"
                  class="text-red-500 text-xs mt-3 -mb-6">
                  <span *ngIf="demoForm.get('phone').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: loginCellPhone } }}
                  </span>
                  <span
                    *ngIf="!demoForm.get('phone').errors['minlength'] && demoForm.get('phone').errors['isPhoneValid']">
                    {{ 'phoneNumberInvalid' | translate }}
                  </span>
                  <span *ngIf="demoForm.get('phone').errors['minlength']">
                    {{ 'invalidAtLeastLengthKeyForm' | translate: { key: loginCellPhone, number: 10 } }}
                  </span>
                </div>
              </div>

              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full logindepaddme">
                  <mat-label for="loginNameInput">{{ loginBussines }}</mat-label>
                  <input id="loginBussinesInput" maxlength="100" formControlName="company" matInput type="text"
                    class="validate" [placeholder]="loginBussines" required
                    oninput="this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.]/g, '').trimStart();">
                </mat-form-field>
                <div
                  *ngIf="demoForm.get('company').invalid && (demoForm.get('company').dirty || demoForm.get('company').touched)"
                  class="text-red-500 text-xs mt-3 -mb-6">
                  <span *ngIf="demoForm.get('company').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: loginBussines } }}
                  </span>
                  <span *ngIf="demoForm.get('company').errors['noSpecialCharacters']">
                    {{ 'invalidCharactersKeyForm' | translate: { key: loginBussines } }}
                  </span>
                </div>
              </div>

              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full logindepaddme">
                  <mat-label for="loginNameInput">{{ loginNumberEmployees }}</mat-label>
                  <mat-select id="loginNumberEmployeesSelect" formControlName="userLimit"
                    [placeholder]="loginNumberEmployees" required>
                    <mat-option *ngFor="let userLimit of userLimits; let i = index"
                      [value]="userLimit.usrlD_DESCRIPTION">
                      {{ userLimit.usrlD_DESCRIPTION }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="demoForm.get('userLimit').invalid && (demoForm.get('userLimit').dirty || demoForm.get('userLimit').touched)"
                  class="text-red-500 text-xs mt-4 -mb-6">
                  <span *ngIf="demoForm.get('userLimit').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: loginNumberEmployees } }}
                  </span>
                </div>
              </div>

              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full logindepaddme">
                  <mat-label for="loginNameInput">{{ loginCountry }}</mat-label>
                  <mat-select id="loginCountrySelect" formControlName="country" [placeholder]="loginCountry" required>
                    <mat-option *ngFor="let country of countries; let i = index" [value]="country.ctrD_NAME">
                      {{ country.ctrD_NAME }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="demoForm.get('country').invalid && (demoForm.get('country').dirty || demoForm.get('country').touched)"
                  class="text-red-500 text-xs mt-4 -mb-4">
                  <span *ngIf="demoForm.get('country').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: loginCountry } }}
                  </span>
                </div>
              </div>

              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full logindepaddme">
                  <mat-label for="loginNameInput">{{ loginCode }}</mat-label>
                  <input id="loginCodeInput" maxlength="20" formControlName="code" matInput type="text" class="validate"
                    [placeholder]="loginCode" oninput="this.value = this.value.trimStart();">
                </mat-form-field>
              </div>

              <re-captcha #captchaComponent (resolved)="resolved($event)" name="captcha"
                siteKey="6LdhgoYpAAAAAKlDW2aRdu6nH_Gmk0KTTU7tcoMr"></re-captcha>

              <div class="py-3">
                <input id="termsAndConditionsAccepted" formControlName="terms" type="checkbox" id="terms" name="terms"
                  required>
                <label for="terms">
                  {{ "loginAskAcept" | translate }}
                  <a class="cursor-pointer color-haibu" href="/terms" target="_blank">{{ "loginTermsAndCondition" |
                    translate }}</a>
                </label>
                <br>
                <mat-error class="error-label"
                  *ngIf="demoForm.controls.terms.invalid && demoForm.controls.terms.touched">
                  {{ 'mustAcceptTerms' | translate }}
                </mat-error>
              </div>

              <app-custom-primary-button id="loginWantACall" [disabled]="sending" color="primary" size="small"
                class="w-full" type="submit" (click)="sendMail()">
                {{ 'loginWantACall' | translate }}
              </app-custom-primary-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white w-full flex flex-col md:flex-row justify-around items-center px-10 pt-6 pb-3 background-green">
    <div class="flex-1 flex justify-start text-white">
      © 2024 Haibu
    </div>
    <div class="flex-1 flex justify-center">
      <a id="loginPrivacyAdvice" href="/notice" target="_blank" class="color-haibu ml-8">
        {{"loginPrivacyAdvice" | translate}}
      </a>
    </div>
    <div class="flex-1 flex justify-start md:pr-8 items-center">
      <a id="stripeBtn" target="_blank" class="cursor-default">
        <img [src]="stripeIcon" alt="Stripe" class="stripe-icon">
      </a>
      <a id="americanExpressIcon" target="_blank" class="cursor-default">
        <img [src]="americanExpressIcon" alt="AmericanExpress"
          class="w-12 sm:w-14 md:w-16 lg:w-16 h-10 sm:h-12 md:h-14 lg:h-16 pr-2 sm:pr-3">
      </a>
      <a id="visaIcon" target="_blank" class="cursor-default">
        <img [src]="visaIcon" alt="Visa" class="w-12 sm:w-14 md:w-16 lg:w-16 h-10 sm:h-12 md:h-14 lg:h-16 pr-2 sm:pr-3">
      </a>
      <a id="mastercardIcon" target="_blank" class="cursor-default">
        <img [src]="mastercardIcon" alt="MasterCard"
          class="w-12 sm:w-14 md:w-16 lg:w-16 h-10 sm:h-12 md:h-14 lg:h-16 pr-2">
      </a>

      <a id="linkedinIcon" class="pt-2 pb-2" href="https://www.linkedin.com/company/rompecabezashrcom" target="_blank">
        <img class="h-12 w-12" [src]="linkedinIcon" alt="">
      </a>
      <a id="igIcon" class="pt-2 pb-2" href="https://www.instagram.com/haibu.hr/" target="_blank">
        <img class="h-12 w-12" [src]="igIcon" alt="">

      </a>
    </div>
  </div>
</div>
