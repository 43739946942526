<div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
      <mat-icon class="close-x-primary">close</mat-icon>
    </button>
  </div>

<div class="p-2 pt-0 relative">
    <div mat-dialog-content>
        <image-cropper class="cropper" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
            [aspectRatio]="imageRatio" [containWithinAspectRatio]="true" [alignImage]="'center'" backgroundColor="white"
            [resizeToWidth]="300" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
        </image-cropper>

        <div class="w-full mt-4 flex flex-col md:flex-row md:items-center md:justify-around">
            <app-custom-primary-button id="candidateCopyQrDialog" size="mini" color="secondary"
                class="text-center mb-2 md:mb-0 md:mr-2" (click)="cancel()">
                {{ 'Cancelar' | translate }}
            </app-custom-primary-button>
            <app-custom-primary-button id="candidateDownloadQrDialog" size="mini" color="secondary" class="text-center"
                (click)="sendCroppedImage()">
                {{ 'Aceptar' | translate }}
            </app-custom-primary-button>
        </div>
    </div>
</div>
