export const environment = {
  production: true,
  apiUrl: 'https://haibuhr.com/develop',
  imageUrl: 'https://webapi.haibuhr.com/',
  candidateUrl: 'https://haibuhr.com/',
  candidateUrlExternal: 'https://haibuhr.com/',
  apiUrlUnsafe: 'https://webapi.haibuhr.com',
  apiDevelBassetUrl: "https://mi.basset.com.mx/",

/*   apiUrl: 'https://devel.haibuhr.com/develop',
  imageUrl: 'https://webapi-devel.haibuhr.com/',
  candidateUrl: 'https://devel.haibuhr.com/',
  candidateUrlExternal: 'https://devel.haibuhr.com/',
  apiUrlUnsafe: 'https://webapi-devel.haibuhr.com',
  apiDevelBassetUrl: "https://devel.portal.basset.com.mx", */

  recaptcha: {
    siteKey: '6LeycoYpAAAAANGS63LdXC02trwbg05LvESNM455',
  }
};

