<div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
        <mat-icon class="close-x-primary">close</mat-icon>
    </button>
</div>

<div class="pb-3 text-center title-text-modal">
    <span [innerHTML]="modalTitle"></span>
</div>

<div class="md:px-6 px-0">
    <form [formGroup]="miFormulario" (ngSubmit)="submitForm()">
        <div class="pt-6 pb-3 flex flex-col">
            <label class="pr-6 pt-2 green-subtitle" for="nombre">{{ 'addClientName' | translate }}</label>
            <div class="flex">
                <input id="addClientName" formControlName="nombre" class="p-2 w-full mt-1 borde" type="text"
                    name="nombre" (keydown.enter)="submitForm()" maxlength="50" (input)="showErrorMessage = false"
                    oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()"]+/g, "").trimStart();' required />
            </div>
            <div *ngIf="miFormulario.get('nombre').invalid && (miFormulario.get('nombre').dirty || miFormulario.get('nombre').touched)"
                class="text-red-500 text-xs mt-1">
                <span *ngIf="miFormulario.get('nombre').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('addClientName' | translate) } }}
                </span>
                <span *ngIf="miFormulario.get('nombre').errors['noSpecialCharacters']">
                    {{ 'invalidCharactersKeyFormCatalogs' | translate }}
                </span>
            </div>
        </div>

        <!-- Usuarios -->
        <div class="flex flex-col w-full">
            <div class="pb-1 flex items-center">
                <span class="green-subtitle">{{'addClientUsers' | translate}}</span>
            </div>
            <mat-select #select1 id="addUsersClient"
                *ngIf="clientsFiltrados.length > 0 && !loadingUsers && !loadingUsersExist" [ngModel]="null"
                name="addUsersClient" class="borde p-3 w-full md:w-2/3 centered-text brown-border"
                (selectionChange)="onClientSelected($event)" (focus)="onFocus(0)" (blur)="onBlur(0)">
                <mat-option *ngFor="let client of clientsFiltrados" [value]="client">
                    {{client.nombre}}
                </mat-option>
            </mat-select>
            <div *ngIf="loadingUsers || loadingUsersExist"
                class="w-full flex justify-center items-center border-spinner">
                <mat-spinner diameter="20" color="primary"></mat-spinner>
            </div>
            <div *ngIf="showErrorMessage1" class="text-red-500 text-xs mt-1">
                <span>
                    {{ 'addClientUsersRequired' | translate }}
                </span>
            </div>
        </div>

        <!-- Usuarios seleccionados -->
        <div class="flex flex-wrap">
            <span *ngFor="let client of selectedClients;"
                class="tag-primary px-2.5 py-0.5 rounded-full mt-3 mr-2 flex items-center">
                <span>{{ client.nombre }}</span>
                <div class="flex items-center justify-center">
                    <mat-icon id="removeUserVacancyDialog3" (click)="removeClient(client)"
                        class="cursor-pointer close-x">cancel</mat-icon>
                </div>
            </span>
        </div>

        <div *ngIf="loadingUsersExist" class="grid grid-cols-3 gap-3 mt-3">
            <div role="status" class="animate-pulse ng-star-inserted w-full pb-1">
                <div class="bg-gray-200 h-6 skelleton"></div>
            </div>
            <div role="status" class="animate-pulse ng-star-inserted w-full pb-1">
                <div class="bg-gray-200 h-6 skelleton"></div>
            </div>
            <div role="status" class="animate-pulse ng-star-inserted w-full pb-1">
                <div class="bg-gray-200 h-6 skelleton"></div>
            </div>
            <div role="status" class="animate-pulse ng-star-inserted w-full pb-1">
                <div class="bg-gray-200 h-6 skelleton"></div>
            </div>
            <div role="status" class="animate-pulse ng-star-inserted w-full pb-1">
                <div class="bg-gray-200 h-6 skelleton"></div>
            </div>
            <div role="status" class="animate-pulse ng-star-inserted w-full pb-1">
                <div class="bg-gray-200 h-6 skelleton"></div>
            </div>
        </div>

        <div class="ml-auto md:w-full mt-6 flex flex-col md:flex-row md:justify-center md:items-center">
            <app-custom-primary-button id="addDocumentSave" type="submit" size="super-small-light" color="primary"
                class="text-center" [disabled]="sending">
                <span *ngIf="!sending">{{ 'addDocumentSave' | translate }}</span>
                <div *ngIf="sending" class="w-full flex justify-center">
                    <mat-spinner diameter="20" color="primary"></mat-spinner>
                </div>
            </app-custom-primary-button>
        </div>
    </form>
</div>